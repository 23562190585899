
const trigger = document.querySelector(".l-footer__trigger");
const target = document.querySelector(".l-footer__target");

trigger.addEventListener("click", function(){
    target.classList.toggle("show");
    trigger.classList.toggle("show");
});


