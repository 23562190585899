const targets = document.querySelectorAll('.p-topNews__list');
const triggers = document.querySelectorAll('.p-topNews__button .c-btn__link');

window.onload = function(){

for(let i = 0; i < triggers.length; i++){
    triggers[i].addEventListener('click', function(){
    for(let i = 0; i < triggers.length; i++){
        triggers[i].classList.remove('selected');
    }
    triggers[i].classList.add('selected');
    
    for(let i = 0; i < targets.length; i++){
        targets[i].classList.remove('is-active');
    }
    targets[i].classList.add('is-active');
    });
};

}