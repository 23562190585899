
const map = document.querySelectorAll(".p-informationMap__img img");
const location = document.querySelectorAll(".p-informationMap__item");


for(let i = 0; i < location.length; i++){
    location[i].addEventListener("mouseover", function(){
        for(let n = 0; n < map.length; n++){
            map[n].classList.remove("show");
        }
        map[i].classList.add("show");
    });
};
