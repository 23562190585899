const trigger = document.querySelectorAll('.js-header-trigger');
const target = document.querySelectorAll('.l-header__nav');
const header = document.querySelectorAll('.l-header');
const headerSp = document.querySelectorAll(".l-header__spWrapper");
const body = document.querySelectorAll('body');
const logo = document.querySelectorAll(".l-header__logo");


// TOPスクロールした時の動き
// const scrollVal = window.pageYOffset;
const wh = window.innerHeight;
// const logo = document.querySelector(".l-header__logo.white");
const bar = document.querySelectorAll(".bar");

	if(document.getElementById("top") !== null) {

		window.addEventListener('scroll', function () {

			const scrollVal = window.pageYOffset;
			// console.log(wh);
			// console.log(scrollVal);
		
			if (scrollVal > (wh - 100)) {
				// console.log(scrollVal)
				// console.log(scrollVal);
				// logo.classList.remove("white");
				for (let i = 0; i < logo.length; i++) {
					logo[i].classList.remove("white");
				}
				for (let i = 0; i < bar.length; i++) {
					bar[i].classList.remove("white");
				}
				for (let i = 0; i < header.length; i++) {
					header[i].classList.add('bg-color');
				}
				for (let i = 0; i < headerSp.length; i++) {
					headerSp[i].classList.add('bg-color');
				}
			} else {
				// logo.classList.add("white");
				for (let i = 0; i < logo.length; i++) {
					logo[i].classList.add("white");
				}
				for (let i = 0; i < bar.length; i++) {
					bar[i].classList.add("white");
				}
				for (let i = 0; i < header.length; i++) {
					header[i].classList.remove('bg-color');
				}
				for (let i = 0; i < headerSp.length; i++) {
					headerSp[i].classList.remove('bg-color');
				}
			}
		});
	

	};




//spのナビ
const html = document.querySelector('html');
for (let i = 0; i < trigger.length; i++) {
	trigger[i].addEventListener('click', function () {
		html.classList.toggle('no-scroll');
		header[i].classList.toggle('show');
		target[i].classList.toggle('show');
		trigger[i].classList.toggle('show');
		body[i].classList.toggle('no-scroll');
		if(headerSp[i].classList.contains("bg-color") == false){
			logo[i].classList.toggle("white");
		}
	});
}

	
	


	//spとiPadのナビの中Companyクリックした時のjs
	const navtrigger = document.querySelectorAll(".l-header__trigger");
	// console.log(trigger);
	const navtarget = document.querySelectorAll(".l-header__target");
	// console.log(target);

	for(let i = 0; i < navtrigger.length; i++){
		navtrigger[i].addEventListener("click", function(){
			navtarget[i].classList.toggle("show");
			navtrigger[i].classList.toggle("show");
			
		});
	}
