// const video = document.querySelector('.video');
// console.log(video);
// const wrapper = document.querySelector('.p-kv__wrapper');
// const section = document.querySelectorAll('.section');

// if(document.getElementById("top") !== null) {
// 	window.addEventListener('scroll', function () {
// 		const scrollVal = window.pageYOffset;
// 		const wh = window.innerHeight;

// 		video.classList.add('bg');
		
// 		if(scrollVal > (wh - 100)) {
// 			video.classList.add('bg');
// 			wrapper.classList.remove('fixed');
			
// 		}else{
// 			video.classList.remove('bg');
// 			wrapper.classList.add('fixed');
// 		}

		

// 		console.log(wh);
// 		console.log(scrollVal);
// 	});
// };
