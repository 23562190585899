const target1 = document.querySelector("#training");
const target2 = document.querySelector("#planDesign");
const target3 = document.querySelector("#global");
const sideBar = document.querySelector(".p-service__sideBar");
const icons = document.querySelectorAll(".p-sideBar__icon");
const items = document.querySelectorAll(".p-sideBar__item");

if(sideBar != null){
    window.addEventListener('scroll', function () {
        const scrollVal = window.pageYOffset;
        var clientRect1 = target1.getBoundingClientRect();
        var clientRect2 = target2.getBoundingClientRect();
        var clientRect3 = target3.getBoundingClientRect();
        var clientRect4 = sideBar.getBoundingClientRect();
        // console.log(scrollVal)
        var y1 = clientRect1.top ;
        var y2 = clientRect2.top ;
        var y3 = clientRect3.top ;
        var y4 = clientRect4.top ;
        // console.log(y1);
        // console.log(y2);
        // console.log(y3);
        // console.log(y4);

        if( 600 < scrollVal && scrollVal < 3650){
            sideBar.classList.add("fixed");
        }
        else{
            sideBar.classList.remove("fixed");
        }


        if(scrollVal < 2000){
            for(let i = 0; i < icons.length; i++){
                icons[i].classList.remove("current");    
            }
            icons[0].classList.add("current");
        }
        else if (scrollVal < 3300){
            for(let i = 0; i < icons.length; i++){
                icons[i].classList.remove("current");    
            }
            icons[1].classList.add("current");
        }
        else{
            for(let i = 0; i < icons.length; i++){
                icons[i].classList.remove("current");    
            }
            icons[2].classList.add("current");
        }

        if((-100 < y1 && y1 < 300) || (-100 < y2 && y2 < 300) || (-100 < y3 && y3 < 300)){
            for(let i = 0; i < items.length; i++){
                items[i].classList.remove("color-black");    
            }
        }
        else{
            for(let i = 0; i < items.length; i++){
                items[i].classList.add("color-black");    
            }
        }
        

        // if(y1 < 100){
        //     for(let i; i < icons.length, i = 0; i++){
        //         icons[i].classList.add("current");

        //     }
        // }
        

    });
}